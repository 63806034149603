import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './components/App'
import IdleTimer from 'react-idle-timer'

import { Provider } from 'react-redux'

import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'

import app from 'common/client/app'
import { authenticate } from 'common/actions'
import { getPatient } from './actions'

// Middleware
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
// const loggerMiddleware = createLogger()

let store = createStore(
  reducers,
  applyMiddleware(
    thunkMiddleware,
    // loggerMiddleware,
  )
)

store.dispatch(authenticate())

let updateMachine = _.debounce((machine) => {
  store.dispatch({ type: 'UPDATE_MACHINE', data: machine })
}, 500, { leading: true, trailing: true })

app.service('machine').on('updated', updateMachine)
app.service('machine').on('patched', updateMachine)

// Listen for machineConfig events from the server
let updateMachineConfig = (machineConfig) => {
  store.dispatch({ type: 'SYNC_CONFIG', data: machineConfig })
  let state = store.getState()
  if (machineConfig.patient) {
    // Get current patient if it exists and is different from what is in the store
    if (machineConfig.patient !== state.currentPatient._id) {
      store.dispatch(getPatient(machineConfig.patient))
    }
  }
  else {
    // Clear the current patient
    store.dispatch({ type: 'CLEAR_PATIENT' })
  }
}

app.service('machineConfig').on('created', updateMachineConfig)
app.service('machineConfig').on('updated', updateMachineConfig)
app.service('machineConfig').on('patched', updateMachineConfig)

app.service('machineConfig').on('removed', (machineConfig) => {
  // When the machine config is deleted, reset the state
  store.dispatch({ type: 'SELECT_MACHINE', data: null })
})

app.service('machineConfig').on('paired', (machineConfig) => {
  store.dispatch({ type: 'SELECT_MACHINE', data: machineConfig })
})

app.service('machineConfig').on('failed', (error) => {
  store.dispatch({ type: 'PAIR_FAILED', data: { message: error.message } })
})

ReactDOM.render(
  <Provider store={store} onUpdate={() => window.scrollTo(0, 0)}>
    <IdleTimer onIdle={() => app.logout()} timeout={120 * 60 * 1000}>
      <App/>
    </IdleTimer>
  </Provider>,
  document.getElementById('root')
)
