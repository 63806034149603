import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPatient } from '../actions'

import Navbar from './Navbar'
import PatientFind from './PatientFind'
import RideConfig from './RideConfig'
import RideReady from './RideReady'
import RideStarted from './RideStarted'
import RideHalted from './RideHalted'
import RideComplete from './RideComplete'

const mapStateToProps = ({ currentPatient, machineConfig: { patient: patientId, status, } }, ownProps) => {
  return {
    currentPatient,
    patientId,
    status,
  }
}

const mapDispatchToProps = {
  getPatient,
}

class RideNew extends Component {
  componentDidMount () {
    if (this.props.patientId) {
      this.props.getPatient(this.props.patientId)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.patientId && this.props.patientId !== prevProps.patientId) {
      this.props.getPatient(this.props.patientId)
    }
  }

  render () {
    let content, title

    if (this.props.status === 'stopped') {
      content = <RideHalted />
    }
    else {
      if (this.props.patientId) {
        if (this.props.currentPatient) {
          let patient = this.props.currentPatient
          title = `${patient.name.given} ${patient.name.family}`
        }
        else {
          title = ''
        }

        if (this.props.status === 'completed') {
          content = <RideComplete />
        }
        else if (this.props.status === 'started' || this.props.status === 'paused') {
          content = <RideStarted />
        }
        else if (this.props.status === 'ready') {
          content = <RideReady />
        }
        else {
          content = <RideConfig/>
        }
      }
      else {
        title = 'Enter Rider ID'
        content = <PatientFind/>
      }
    }

    return (
      <div>
        <Navbar title={title} />
        {content}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RideNew)
