import { combineReducers } from 'redux'
import update from 'immutability-helper'

const currentUser = (state = {}, action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN':
      return action.user
    case 'USER_IS_ANONYMOUS':
      return {}
    default:
      return state
  }
}

const defaultPatient = {
  name: {
    given: '',
    family: '',
  },
}

const currentPatient = (state = defaultPatient, action) => {
  switch (action.type) {
    case 'GET_PATIENT':
    case 'SELECT_PATIENT':
      state = action.data
      return state
    case 'CLEAR_PATIENT':
      state = defaultPatient
      return state
    default:
      return state
  }
}

const machines = (state = [], action) => {
  switch (action.type) {
    case 'GET_MACHINES':
      // @todo we should use a better merging strategy here to avoid duplicates
      state = update(state, { $push: action.data })
      return state
    case 'UPDATE_MACHINE': {
      // @todo this may not work correctly
      let { data: machine } = action
      let index = state.findIndex(item => item._id === machine._id)
      state = update(state, { $splice: [[ index, 1, machine ]] })
      return state
    }
    case 'CLEAR_MACHINES':
      state = []
      return state
    default:
      return state
  }
}

const machineConfig = (state = {}, action) => {
  switch (action.type) {
    case 'MACHINE_PAIR': {
      state = action.data
      return state
    }
    case 'PAIR_CANCELLED': {
      state = {}
      return state
    }
    case 'PAIR_FAILED': {
      state = {}
      return state
    }
    case 'SELECT_MACHINE': {
      state = { ...action.data }
      return state
    }
    case 'SELECT_PATIENT': {
      let patient = action.data
      state = update(state, { patient: { $set: patient } })
      return state
    }
    case 'UPDATE_RIDE': {
      state = update(state, { sets: { $set: action.data } })
      return state
    }
    case 'CHANGE_STATUS': {
      state = update(state, { status: { $set: action.data } })
      return state
    }
    case 'SYNC_CONFIG': {
      state = update(state, { $set: action.data })
      return state
    }
    // the machine object has changed, update our machineConfig...
    case 'UPDATE_MACHINE': {
      let machine = action.data
      if (state.machine && machine._id === state.machine._id) {
        state = update(state, { machine: { $set: machine } })
      }
      return state
    }
    default:
      return state
  }
}

const idle = (state = true, action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN':
    case 'USER_IS_ANONYMOUS':
    case 'UNSET_IDLE':
      state = false
      return state
    case 'SET_IDLE':
      state = true
      return state
    default:
      return state
  }
}

const messages = (state = {}, action) => {
  switch (action.type) {
    case 'DISMISS_MODAL':
      state = {}
      return state
    case 'PAIR_FAILED':
      state = { type: 'error', message: action.data.message, buttonText: 'Try Again' }
      return state
    default:
      return state
  }
}

const reducers = combineReducers({
  currentUser,
  currentPatient,
  machineConfig,
  machines,
  messages,
  idle,
})

export default reducers
