import React, { Component } from 'react'

class ReturnHome extends Component {
  render () {
    return (
      <div className="returnHome">
        <div className="loading">
          <div className="circle"></div>
          <div className="circle green"></div>
        </div>
        <p>Please wait while this machine resets to the home position.</p>
      </div>
    )
  }
}

export default ReturnHome
