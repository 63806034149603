import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../styles/login.scss'

class Login extends Component {
  render () {
    // If we are using a remote API, redirect back here after login
    let url = `${process.env.REACT_APP_LOGIN_URL}user/login?destination=${window.location.href}`

    return (
      <div className="page login">
        <img className="brand" alt="AllCore 360" src={require('../img/ac-logo-full.png')}/>
        <footer>
          <a className="button primary" href={url}>Login</a>
        </footer>
      </div>
    )
  }
}

export default connect()(Login)
