import React, { Component } from 'react'
import { connect } from 'react-redux'
import { findLastPatientRide, selectPatient, readyRide, findPatientByID, setIdle, resetMachine } from '../actions'

import InputNumeric from './InputNumeric'

import update from 'immutability-helper'
import IdleTimer from 'react-idle-timer'
import classNames from 'classnames'
import uuid from 'uuid/v1'

import { calculateCoreScore } from 'common/functions'

import '../styles/rideConfig.scss'

const mapStateToProps = ({ machineConfig: { _id: configId, patient: patientId, group, sets = [] } }) => {
  return {
    configId,
    patientId,
    group,
    sets,
  }
}

const mapDispatchToProps = {
  findLastPatientRide,
  readyRide,
  resetMachine,
  setIdle,
  findPatientByID,
  selectPatient
}

class RideConfig extends Component {
  defaultSet = { spins: 5, degrees: 45, direction: 'CW' }

  state = {
    coreScore: 0,
    sets: [],
  }

  onIdle = () => {
    this.props.resetMachine(this.props.configId)
    this.props.setIdle()
  }

  async componentDidMount () {
    let patientId = new URL(window.location.href).searchParams.get('patient')

    // If we landed on this page with a passed patient from ac360 manage
    if (patientId) {
      let patient = await this.props.findPatientByID(patientId, this.props.group)
      if (patient) {
        // Select this patient and get the last session
        await this.props.selectPatient(this.props.configId, patient)
      }
    }

    let passedRide = JSON.parse(new URL(window.location.href).searchParams.get('rideData'))
    let ride = await this.props.findLastPatientRide(this.props.patientId)

    if (this.props.sets && this.props.sets.length) {
      let rideConfig = {
        sets: this.props.sets
      }
      calculateCoreScore(rideConfig)
      this.setState({
        sets: rideConfig.sets,
        coreScore: rideConfig.coreScore,
      })
    }
    else {
      if (ride) {
        // Find the first exerciseResult that is of equipmentType allcore360
        if (!passedRide) {
          passedRide = ride
        }
        // Make sure the set has all the necessary values
        // @todo actually validate every value
        passedRide.sets = passedRide.sets.map(set => ({ ...this.defaultSet, ...set, _key: uuid() }))
        calculateCoreScore(passedRide)
        this.setState({
          sets: passedRide.sets,
          coreScore: passedRide.coreScore,
        })
      }
      // If they don't have one check for a passed ride
      else if (passedRide) {
        passedRide.sets = passedRide.sets.map(set => ({ ...this.defaultSet, ...set, _key: uuid() }))
        calculateCoreScore(passedRide)
        this.setState({
          sets: passedRide.sets,
          coreScore: passedRide.coreScore,
        })
      }
      // Otherwise use the default set
      else {
        let defaultRide = { sets: [ { ...this.defaultSet, _key: uuid() } ] }
        calculateCoreScore(defaultRide)
        this.setState({
          sets: defaultRide.sets,
          coreScore: defaultRide.coreScore,
        })
      }
    }

    // Clear any previous patient parameters
    window.history.pushState({}, document.title, '/')
  }

  addSet () {
    // Get the last set added and add a copy of it to the current ride
    let nextSet
    if (this.state.sets.length) {
      nextSet = { ...this.state.sets[this.state.sets.length - 1], _key: uuid(), isNew: true }
    }
    else {
      nextSet = { ...this.defaultSet, _key: uuid(), isNew: true }
    }

    let rideConfig = {
      sets: update(this.state.sets, { $push: [ nextSet ] })
    }
    calculateCoreScore(rideConfig)
    this.setState({
      sets: rideConfig.sets,
      coreScore: rideConfig.coreScore,
    })

    // Make sure we're at the bottom so we can see the new set if we've got a bunch
    setTimeout(() => {
      window.scrollTo({
        top: window.innerHeight + 500,
        behavior: 'smooth'
      })
    }, 300)
  }

  removeSet (index) {
    let rideConfig = {
      sets: update(this.state.sets, { $splice: [[index, 1]] })
    }
    calculateCoreScore(rideConfig)
    this.setState({
      sets: rideConfig.sets,
      coreScore: rideConfig.coreScore,
    })
  }

  handleIncrementHold (parameter, index) {
    this.incrementInterval = setInterval(() => {
      this.increment(parameter, index)
    }, 150)
  }

  handleDecrementHold (parameter, index) {
    this.decrementInterval = setInterval(() => {
      this.decrement(parameter, index)
    }, 150)
  }

  handleRelease () {
    clearInterval(this.incrementInterval)
    clearInterval(this.decrementInterval)
  }

  updateSet (set, index) {
    // @todo make this a reusable validator
    if (set.spins < 1) {
      set.spins = 1
    }
    if (set.degrees < 0) {
      set.degrees = 0
    }
    if (set.degrees > 90) {
      set.degrees = 90
    }

    let rideConfig = {
      sets: update(this.state.sets, { $splice: [[ index, 1, set ]] })
    }
    calculateCoreScore(rideConfig)
    this.setState({
      sets: rideConfig.sets,
      coreScore: rideConfig.coreScore,
    })
  }

  increment (parameter, index) {
    // Add 1 to the target set
    let targetSet = { ...this.state.sets[index] }
    targetSet[parameter]++

    this.updateSet(targetSet, index)
  }

  decrement (parameter, index) {
    // Subtract 1 from the target set
    let targetSet = { ...this.state.sets[index] }
    targetSet[parameter]--

    this.updateSet(targetSet, index)
  }

  changeDirection (event, index) {
    let targetSet = { ...this.state.sets[index] }
    targetSet['direction'] = event.target.checked ? 'CW' : 'CCW'

    this.updateSet(targetSet, index)
  }

  updateParameter = (parameter, value, index) => {
    // Set the correct set's correct parameter to the value entered
    let targetSet = { ...this.state.sets[index] }
    targetSet[parameter] = value

    this.updateSet(targetSet, index)
  }

  ready () {
    // @todo update the machineConfig and emit an event that we're ready to ride...
    this.props.readyRide(this.props.configId, this.state.sets)
    //    this.props.changeStatus(this.props.configId, 'ready')
  }

  render () {
    return (
      <IdleTimer onIdle={this.onIdle} timeout={120000}>
        <div className="page rideConfig">
          <h2>Configure Ride</h2>
          <ol>
            {
              this.state.sets.map((set, index) => {
                return (
                  <li key={index} className={classNames({ isNew: set.isNew })}>
                    <div className="set">
                      <div className="parameter parameter-spins">

                        <div onClick={() => this.decrement('spins', index)}
                          onTouchStart={() => this.handleDecrementHold('spins', index)}
                          onMouseDown={() => this.handleDecrementHold('spins', index)}
                          onTouchEnd={() => this.handleRelease('spins', index)}
                          onMouseUp={() => this.handleRelease('spins', index)}
                          className="stepper less"></div>

                        <InputNumeric index={index} onChange={(value) => this.updateParameter('spins', value, index)} value={set.spins} />

                        <div onClick={() => this.increment('spins', index)}
                          onTouchStart={() => this.handleIncrementHold('spins', index)}
                          onMouseDown={() => this.handleIncrementHold('spins', index)}
                          onTouchEnd={() => this.handleRelease('spins', index)}
                          onMouseUp={() => this.handleRelease('spins', index)}
                          className="stepper more"></div>

                        <label htmlFor="spins">
                        Spins at
                        </label>
                      </div>
                      <div className="parameter parameter-degrees">

                        <div onClick={() => this.decrement('degrees', index)}
                          onTouchStart={() => this.handleDecrementHold('degrees', index)}
                          onMouseDown={() => this.handleDecrementHold('degrees', index)}
                          onTouchEnd={() => this.handleRelease('degrees', index)}
                          onMouseUp={() => this.handleRelease('degrees', index)}
                          className="stepper less"></div>

                        <InputNumeric index={index} onChange={(value) => this.updateParameter('degrees', value, index)} value={set.degrees} />

                        <div onClick={() => this.increment('degrees', index)}
                          onTouchStart={() => this.handleIncrementHold('degrees', index)}
                          onMouseDown={() => this.handleIncrementHold('degrees', index)}
                          onTouchEnd={() => this.handleRelease('degrees', index)}
                          onMouseUp={() => this.handleRelease('degrees', index)}
                          className="stepper more"></div>

                        <label htmlFor="degrees">
                        Degrees
                        </label>
                      </div>
                      <label className="parameter parameter-direction">
                        <input
                          name="direction"
                          type="checkbox"
                          checked={set.direction === 'CW'}
                          onChange={(event) => this.changeDirection(event, index)}
                        />
                        <i className={`direction ${set.direction.toLowerCase()}`}></i>
                        <div className={`direction-label direction-${set.direction.toLowerCase()}-label`}>
                          <div>Counter</div>
                          <div>Clockwise</div>
                        </div>
                      </label>
                      <div className={classNames(this.state.sets.length > 1 ? 'delete active' : 'delete')} onClick={() => this.removeSet(index)}></div>
                    </div>
                  </li>
                )
              })
            }
          </ol>
          <div className="row">
            <button onClick={() => this.addSet()} className="button secondary">Add Set</button>
          </div>
          <footer>
            <div className="coreScore">
              Projected Core Score
              <i className="icon star"/>
              <span>{this.state.coreScore}</span>
            </div>
            <div className="row">
              <button onClick={() => this.ready()} className="button primary">Ready</button>
            </div>
          </footer>
        </div>
      </IdleTimer>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RideConfig)
