import React, { Component } from 'react'

class InputNumeric extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      setTimeout(() => this.setState({ value: this.props.value }), 0)
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  render () {
    return (
      <input
        size={2}
        maxLength={2}
        onChange={this.handleChange}
        onBlur={(event) => this.props.onChange(this.state.value)}
        value={this.state.value} />
    )
  }
}

export default InputNumeric
