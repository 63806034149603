import React, { Component } from 'react'
import { connect } from 'react-redux'
import { findPatientByID, selectPatient, setIdle } from '../actions'
import IdleTimer from 'react-idle-timer'
import '../styles/patientFind.scss'

const mapStateToProps = ({ machineConfig: { _id: configId, group } }, ownProps) => {
  return {
    configId,
    group
  }
}

const mapDispatchToProps = {
  findPatientByID,
  selectPatient,
  setIdle
}

class PatientFind extends Component {
  componentWillMount () {
    let patientId = new URL(window.location.href).searchParams.get('patient')
    if (patientId) {
      this.submit(null, patientId)
    }
  }

  state = {
    riderId: process.env.REACT_APP_PATIENT_ID || '',
    warning: false,
  }

  updateId = (num) => {
    if (this.state.riderId.length < 8) {
      this.setState({ riderId: '' + this.state.riderId + num, warning: false, })
    }
  }

  backspace = () => {
    let entry = this.state.riderId
    this.setState({ riderId: entry.substring(0, entry.length - 1), warning: false })
  }

  handleChange = (event) => {
    // @todo validate this field better
    if (event.target.value.length <= 8) {
      this.setState({ riderId: event.target.value, warning: false })
    }
  }

  submit = async (event, id) => {
    // If we passed an id from a url param when we mounted, use that
    let patientId
    if (id) {
      patientId = id
    }
    else {
      patientId = this.state.riderId
    }

    // Get the patient
    try {
      let patient = await this.props.findPatientByID(patientId, this.props.group)
      if (patient) {
        this.props.selectPatient(this.props.configId, patient)
      }
      else {
        // Not found
        this.setState({ warning: true })
      }
    }
    catch (err) {
      // Error
      console.log('error', err)
      this.setState({ warning: true })
    }
  }

  render () {
    return (
      <IdleTimer onIdle={this.props.setIdle} timeout={120000}>
        <div className="page patientFind">
          <div className={`warning ${this.state.warning && 'show'}`}>
            <i className="icon searchError"/> Unable to find a rider with that ID.
          </div>
          <input type="number" className="idEntry" value={this.state.riderId} onChange={this.handleChange} />

          <div className="pinPad">
            <div className="row">
              <button className="number" onClick={() => this.updateId(1)}>1</button>
              <button className="number" onClick={() => this.updateId(2)}>2</button>
              <button className="number" onClick={() => this.updateId(3)}>3</button>
            </div>
            <div className="row">
              <button className="number" onClick={() => this.updateId(4)}>4</button>
              <button className="number" onClick={() => this.updateId(5)}>5</button>
              <button className="number" onClick={() => this.updateId(6)}>6</button>
            </div>
            <div className="row">
              <button className="number" onClick={() => this.updateId(7)}>7</button>
              <button className="number" onClick={() => this.updateId(8)}>8</button>
              <button className="number" onClick={() => this.updateId(9)}>9</button>
            </div>
            <div className="row left">
              <button className="backspace" onClick={() => this.backspace()}><i className="icon backspace"/></button>
              <button className="number" onClick={() => this.updateId(0)}>0</button>
            </div>
          </div>
          <div className="row">
            <a href={process.env.REACT_APP_MANAGE_URL} className="button primary">Search for Rider</a>
            <button onClick={this.submit} className="button primary" disabled={this.state.riderId.length !== 8}>Load Rider</button>
          </div>
        </div>
      </IdleTimer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientFind)
