import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logout } from 'common/actions'
import { changeStatus, resetMachine, unpairMachine } from '../actions'
import classNames from 'classnames'
import '../styles/navbar.scss'

const mapStateToProps = ({ machines, machineConfig }) => {
  let machine = machines.find(machine => machine._id === machineConfig.machine)
  let readableStatus

  switch (machineConfig.status) {
    case 'stopped':
      readableStatus = 'Stopped'
      break
    case 'started':
      readableStatus = 'Riding...'
      break
    case 'paused':
      readableStatus = 'Paused'
      break
    case 'completed':
      readableStatus = 'Finished'
      break
    case 'ready':
      readableStatus = 'Ready'
      break
    default:
      readableStatus = 'Idle'
  }

  return {
    machineConfig,
    machine,
    patient: machineConfig.patient,
    status: machineConfig.status,
    mode: machine && machine.mode,
    readableStatus,
  }
}

const mapDispatchToProps = {
  changeStatus,
  resetMachine,
  unpairMachine,
  logout,
}

class Navbar extends Component {
  state = { unpairing: 'paired' }

  back = () => {
    // If we're ready to ride,
    // change the status of the ride to go back to RideConfigure
    if (this.props.status === 'ready') {
      this.props.changeStatus(this.props.machineConfig._id, 'idle')
    }
    // If we haven't started our ride,
    // clear the patient to go back to PatientFind
    else if (this.props.status === 'idle') {
      this.props.resetMachine(this.props.machineConfig._id)
    }
  }

  handleButtonPress = () => {
    // @todo use mode?
    // Only allow unpairing if a ride hasn't been started, or has been completed
    if (this.props.status === 'completed' || this.props.status === 'idle' || this.props.status === 'ready') {
      this.setState({ unpairing: 'unpairing' })
      this.unpairTimer = setTimeout(() => this.setState({ unpairing: 'prompt' }), 1000)
    }
  }

  unpair = () => {
    this.props.unpairMachine(this.props.machineConfig._id)
  }

  handleButtonRelease = () => {
    if (this.state.unpairing !== 'prompt') {
      this.setState({ unpairing: 'paired' })
    }

    clearTimeout(this.unpairTimer)
  }

  dismissModal = () => {
    this.setState({ unpairing: 'paired' })
  }

  render () {
    let labelStyle = {
      color: 'white',
      position: 'absolute',
      textTransform: 'uppercase',
      marginTop: 10,
      fontSize: 14
    }

    if ((this.props.status === 'idle' || this.props.status === 'ready') && this.props.patient) {
      labelStyle.marginLeft = 30
    }

    return (
      <div className={classNames('navbarWrapper', this.props.status)}>
        <nav className="navbar">
          {
            (this.props.status === 'idle' || this.props.status === 'ready') && this.props.patient &&
            <i onClick={this.back} className="icon back" />
          }
          <div style={labelStyle}>Control</div>
          {
            (this.props.status === 'idle' || this.props.status === 'ready' || this.props.status === undefined) &&
            <i onClick={this.props.logout} className="icon logout" />
          }
          <h2>
            {this.props.title}
          </h2>
        </nav>

        {
          this.props.machine &&
          <div className="machineInfo">

            <div className={ classNames(
              'statusbar',
              { 'pressed': this.state.unpairing === 'unpairing' },
              { 'hidden': this.state.unpairing === 'prompt' }
            )} >

              <div className="statusbar-item statusbar-label status">
                <i className="icon brand"/>
                {this.props.readableStatus}
              </div>

              <div className="statusbar-item statusbar-label tilt">
                <i className="icon angle" />
                { this.props.machine.currentAngle }°
              </div>

              <h3 onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                className="machineName statusbar-label">

                <i className="icon pair" />
                {this.props.machine.name}

              </h3>

            </div>

            { /* Confirmation message on desktop */ }
            <div className={ classNames(
              'unpairPrompt',
              { 'visible': this.state.unpairing === 'prompt' }
            )} >
              <div className="message">Unpair this device from {this.props.machine.name}?</div>
              <button onClick={() => this.setState({ unpairing: 'paired' })} className="cancel">Cancel</button>
              <button onClick={this.unpair} className="confirm">Unpair</button>
            </div>

            { /* Confirmation message on mobile */ }
            <div className={classNames('modal', { 'active': this.state.unpairing === 'prompt' })}>
              <p>Are you sure you want to unpair this device from {this.props.machine.name}?</p>
              <div className="row">
                <button onClick={this.dismissModal} className="button secondary">Cancel</button>
                <button onClick={this.unpair} className="button primary">Unpair</button>
              </div>
            </div>

          </div>
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
