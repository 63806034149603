import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dismissModal } from '../actions'
import classNames from 'classnames'

const mapStateToProps = ({ messages }) => {
  return {
    messages
  }
}

const mapDispatchToProps = {
  dismissModal
}

class Modal extends Component {
  render () {
    return (
      <div className={classNames('modal', { 'active': this.props.messages.message })}>
        {
          this.props.messages.message &&
          <div>
            <i className={classNames('icon', this.props.messages.type)} />
            <p>{this.props.messages.message}</p>
            <div className="row">
              <button onClick={() => this.props.dismissModal()} className="button secondary white">
                {this.props.messages.buttonText}
              </button>
            </div>
          </div>
        }
      </div>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
