import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setIdle, getRides, resetMachine } from '../actions'

import IdleTimer from 'react-idle-timer'
import ReturnHome from './ReturnHome'
import IconDirection from './IconDirection'
import '../styles/rideComplete.scss'
import { Mode } from 'common/constants'
import AnimationCount from 'react-count-animation'

import { calculateCoreScore } from 'common/functions'

const mapStateToProps = ({ currentPatient, machines, machineConfig }) => {
  let { _id: configId, ride, sets, status } = machineConfig
  let machine = machines.find(machine => machine._id === machineConfig.machine)
  return {
    currentPatient,
    configId,
    ride,
    sets,
    status,
    mode: machine ? machine.mode : null,
  }
}

const mapDispatchToProps = {
  setIdle,
  resetMachine,
  getRides,
}

class RideComplete extends Component {
  state = {
    coreScore: 0,
    personalBest: 0,
  }

  async componentDidMount () {
    let allRides = await this.props.getRides(this.props.currentPatient._id)

    // Get all the core scores to see the personal best
    let coreScores = allRides.map(ride => ride.coreScore).filter(Number)
    let personalBest = Math.max.apply(null, coreScores)

    let rideConfig = {
      sets: this.props.sets
    }

    calculateCoreScore(rideConfig)

    this.setState({
      coreScore: rideConfig.coreScore,
      personalBest,
    })
  }

  resetMachine = () => {
    this.props.resetMachine(this.props.configId)
    window.location = process.env.REACT_APP_MANAGE_URL
  }

  onIdle = () => {
    this.props.resetMachine(this.props.configId)
    this.props.setIdle()
  }

  render () {
    let countSettings = {
      start: 0,
      count: this.state.coreScore,
      duration: 2000,
      decimals: 0,
      animation: 'up',
    }

    return (

      <div className="page rideComplete">

        {
          this.props.mode === Mode.RETURN_HOME &&
          <ReturnHome />
        }
        <h3>Ride Summary</h3>
        <ol className="rideSets">
          {
            this.props.sets.map((set, index) => {
              return (
                <li key={index}>
                  <div className="parameter">
                    <span className="number">{set.spins}</span>
                    Spins at
                  </div>
                  <div className="parameter">
                    <span className="number">{set.degrees}</span>
                    Degrees
                  </div>
                  <IconDirection direction={set.direction} color="white" />
                </li>
              )
            })
          }
        </ol>

        {
          this.state.coreScore > this.state.personalBest
            ? <div className="flexRow coreScoreWrapper best">
              <h3>Core Score</h3>
              <i className="icon trophy"/>
              <span className="score">
                <AnimationCount {...countSettings}/>
              </span>
              <footer style={{ color: '#77ba3e' }} className="best">Personal Best</footer>
            </div>
            : <div className="flexRow coreScoreWrapper">
              <h3>Core Score</h3>
              <span style={{ color: '#77ba3e' }} className="score">
                <i className="icon star"/>
                <AnimationCount {...countSettings}/>
              </span>
            </div>
        }

        <footer className="row">

          {
            this.props.mode !== Mode.RETURN_HOME &&
            <IdleTimer onIdle={this.onIdle} events={[]} timeout={15000}>
              <button onClick={this.resetMachine} className="button primary" disabled={this.props.mode !== Mode.READY}>Start New Ride</button>
            </IdleTimer>
          }

        </footer>
      </div>

    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RideComplete)
