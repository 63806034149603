import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { changeStatus, returnHome } from '../actions'
import ReturnHome from './ReturnHome'
import '../styles/rideHalted.scss'

import { Mode } from 'common/constants'

const mapStateToProps = ({ machines, machineConfig }) => {
  let machine = machines.find(machine => machine._id === machineConfig.machine)
  return {
    machineConfig,
    status: machineConfig.status,
    mode: machine ? machine.mode : null,
  }
}

const mapDispatchToProps = {
  changeStatus,
  returnHome,
}

class RideHalted extends Component {
  state = { showConfirm: false }

  returnHome = async () => {
    await this.props.returnHome(this.props.machineConfig._id)
  }

  render () {
    if (this.props.mode === Mode.RETURN_HOME) {
      return (
        <div className="page rideHalted">
          <div className="modal active">
            <ReturnHome />
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="page rideHalted">

          <div className={classNames('prompt', { 'show': this.props.mode !== Mode.HALTED && this.state.showConfirm })}>
            <i className="icon error"/>
            <p>Has the rider been safely removed from the machine? This will reset this machine to the home position.</p>
            <div className="row">
              <button onClick={() => this.setState({ showConfirm: false })} className="button secondary">Cancel</button>
              <button onClick={this.returnHome} className="button secondary" disabled={this.props.mode === 2}>Go Home</button>
            </div>
          </div>

          <div className={classNames('prompt', { 'show': this.props.mode !== Mode.HALTED && !this.state.showConfirm })}>
            <i className="icon joystick"/>
            <p>Use the joystick controls to adjust the angle and rotation of the machine and safely remove the rider.</p>
            <div className="row">
              <button onClick={() => this.setState({ showConfirm: true })} className="button secondary">Go Home</button>
            </div>
          </div>

          <div className={classNames('instructions', { 'show': this.props.mode === Mode.HALTED })}>
            <p>You must remove the Emergency Lock to adjust the position of this machine.</p>
          </div>

          {
            /**
             * @todo we don't have a way to change the mode on the machine in simulate mode right now
            process.env.REACT_APP_RIDE_SIMULATE &&
            <footer className={this.props.status === 'stopped' && this.props.mode === Mode.HALTED ? 'show' : ''}>
              <a onClick={() => this.props.changeStatus(this.props.machineConfig._id, 'manual')} className="button secondary">Release Emergency Button</a>
            </footer>
            **/
          }

        </div>
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RideHalted)
