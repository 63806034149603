import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pauseRide, resumeRide, manualMode, returnHome } from '../actions'
import classNames from 'classnames'
import ReturnHome from './ReturnHome'
import IconDirection from './IconDirection'
import '../styles/rideReady.scss'

const RETURN_HOME = 2

const mapStateToProps = ({ machines, machineConfig }) => {
  let { _id: configId, sets, progress = { setIndex: 0, spinsCompleted: 0 }, status } = machineConfig
  let machine = machines.find(machine => machine._id === machineConfig.machine)
  let { setIndex, spinsCompleted } = progress
  let currentSet = sets[setIndex]
  let spinsRemaining = currentSet.spins - spinsCompleted
  return {
    configId,
    sets,
    progress,
    setIndex,
    spinsCompleted,
    spinsRemaining,
    status,
    mode: machine ? machine.mode : null,
  }
}

const mapDispatchToProps = {
  pauseRide,
  resumeRide,
  manualMode,
  returnHome,
}

class RidePaused extends Component {
  state = { showConfirm: false }

  returnHome = () => {
    this.props.returnHome(this.props.configId)
  }

  render () {
    if (this.props.mode === RETURN_HOME) {
      return (
        <div className="modal active">
          <ReturnHome />
        </div>
      )
    }
    else {
      return (
        <div className={classNames('prompt', { 'show': this.props.status === 'paused' })}>
          {
            this.state.showConfirm
              ? <div className="message">
                <i className="icon error"/>
                <p>Are you sure you want to abandon this ride? The machine will reset to the home position.</p>
                <div className="row">
                  <button onClick={() => this.setState({ showConfirm: false })} className="button secondary">Cancel</button>
                  <button onClick={this.returnHome} className="button primary">Yes, Go Home</button>
                </div>
              </div>
              : <div className="row">
                <button onClick={() => this.props.resumeRide(this.props.configId)} className="button primary">Resume Ride</button>
              </div>
          }

          <footer className="row">
            <button onClick={() => this.setState({ showConfirm: true })} className="button secondary" disabled={this.state.showConfirm}>Go Home</button>
            <button onClick={() => this.props.manualMode(this.props.configId)} className="button error">Manual Mode</button>
          </footer>

        </div>
      )
    }
  }
}

class RideStarted extends Component {
  render () {
    // Calculate the offset of the ride status rather than using data-index and CSS
    let offsetTop = 70 * this.props.progress.setIndex

    return (
      <div className={classNames('page', 'rideReady', this.props.status)}>

        <RidePaused { ...this.props } />

        <div className="currentRide">
          <div className="rideStatus" style={{ top: offsetTop }}>
            <span>{ this.props.spinsRemaining }</span>
            {this.props.spinsRemaining === 1 ? 'spin remaining' : 'spins remaining'}
          </div>

          <ol className="rideSets">
            {
              this.props.sets.map((set, index) => {
                return (
                  <li key={index} className={classNames({ 'active': this.props.setIndex === index }, { 'complete': this.props.setIndex > index })}>
                    <div className="parameter">
                      <span className="number">{set.spins}</span>
                      Spins at
                    </div>
                    <div className="parameter">
                      <span className="number">{set.degrees}</span>
                      Degrees
                    </div>
                    <IconDirection direction={set.direction} color="white" />
                  </li>
                )
              })
            }
          </ol>
        </div>

        <footer className="row fixed">
          {
            this.props.status === 'started' &&
            <button onClick={() => this.props.pauseRide(this.props.configId)} className="button secondary white">Pause Ride</button>
          }
        </footer>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RideStarted)
