import React, { Component } from 'react'
import classNames from 'classnames'

class IconDirection extends Component {
  render () {
    return (
      <div className={classNames('parameter', this.props.direction.toLowerCase())}>
        <div className={classNames('direction', this.props.direction.toLowerCase(), this.props.color)}>
        </div>
        <label><span>Counter</span>Clockwise</label>
      </div>
    )
  }
}

export default IconDirection
