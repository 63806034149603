import app from 'common/client/app'

/**
 * Toggle Idle
 */
export function setIdle () {
  return dispatch => {
    dispatch({ type: 'SET_IDLE' })
  }
}

/*
 * Clear machines
 */
export function clearMachines () {
  return dispatch => {
    dispatch({ type: 'CLEAR_MACHINES' })
  }
}

/**
 * Get machines (AllCore360 devices) for a group
 */
export function getMachines (groupId) {
  return async dispatch => {
    try {
      let { data: machines } = await app.service('machine').find({ query: { group: groupId } })
      dispatch({
        type: 'GET_MACHINES',
        data: machines
      })
      return machines
    }
    catch (err) {
      console.log(err)
    }
  }
}

/**
 * Get the patient by _id
 */
export function getPatient (id) {
  return async dispatch => {
    let patient = await app.service('patient').get(id)
    dispatch({ type: 'GET_PATIENT', data: patient })
    return patient
  }
}

/**
 * Find the patient by group and 8-digit patientID
 */
export function findPatientByID (patientID, group) {
  return async dispatch => {
    // get the patient for this patientID and group
    let { data: [ patient ] } = await app.service('patient').find({
      query: {
        'patientID': patientID,
        'group': group,
        '$limit': 1,
      }
    })
    return patient
  }
}

/**
 * Find the latest completed program session for a patient by patient _id
 */
export function findLastPatientRide (id) {
  return async dispatch => {
    let [ ride ] = await app.service('ride').find({
      query: {
        'patient': id,
        'status': 1,
        '$limit': 1,
        '$sort': {
          'startTime': -1,
        },
      },
    })
    return ride
  }
}

/**
 * Get all AllCore sessions for a patient
 */
export function getRides (id) {
  return async dispatch => {
    let rides = await app.service('ride').find({
      query: {
        'patient': id,
        'status': 1,
      },
    })

    return rides
  }
}

/**
 * Pair with machine
 */
export function pairMachine (machine) {
  return async dispatch => {
    try {
      // create a new machineConfig
      let machineConfig = await app.service('machineConfig').create({ machine: machine._id })
      dispatch({ type: 'MACHINE_PAIR', data: machineConfig })
    }
    catch (error) {
      console.log(error)
      // the server will explain what actually happened. There may be multiple reasons that pairing failed.
      dispatch({ type: 'PAIR_FAILED', data: { message: error.message } })
    }
  }
}

/**
 * Stop pairing with machine
 */
export function cancelMachinePair (machineConfig) {
  return async dispatch => {
    try {
      await app.service('machineConfig').remove(machineConfig._id)
      dispatch({ type: 'PAIR_CANCELLED' })
    }
    catch (error) {
      console.log(error)
      dispatch({ type: 'PAIR_CANCELLED' })
    }
  }
}

/**
 * Select machine
 */
export function confirmMachinePair (machineConfig) {
  return async dispatch => {
    // This is only for simulation
    if (process.env.REACT_APP_RIDE_SIMULATE) {
      // pair the machineConfig
      await app.service('machineConfig').patch(machineConfig._id, { paired: true })
      dispatch({ type: 'SELECT_MACHINE', data: machineConfig })
    }
  }
}

/**
 * Unpair with currently paired machine
 */
export function unpairMachine (machineId) {
  return async dispatch => {
    await app.service('machineConfig').remove(machineId)
    dispatch({ type: 'SELECT_MACHINE', data: null })
  }
}

/**
 * Select patient
 */
export function selectPatient (id, patient) {
  return async dispatch => {
    // Dispatch before patching
    dispatch({ type: 'SELECT_PATIENT', data: patient })
    app.service('machineConfig').patch(id, {
      patient: patient._id,
    })
  }
}

/**
 * Update current ride
 */
export function updateRide (id, sets) {
  return async dispatch => {
    // @todo make sure that action.data is an array

    // Validate ride parameter inputs before entering the store
    // Do this here (instead of in the reducer) because once `sets` gets
    // dispatched and patched separately, and we want to make sure the
    // same data gets sent both places
    sets.forEach(set => {
      if (set.spins < 1) {
        set.spins = 1
      }
      if (set.degrees < 0 || set.degrees < 1) {
        set.degrees = 0
      }
      if (set.degrees > 90) {
        set.degrees = 90
      }
    })

    // Dispatch before patching for UI performance
    dispatch({ type: 'UPDATE_RIDE', data: sets })
    app.service('machineConfig').patch(id, {
      sets: sets,
    })
  }
}

/**
 * Change machine status
 */
export function changeStatus (id, status, data = {}) {
  return async dispatch => {
    let machineConfig = await app.service('machineConfig').patch(id, { status, ...data })
    // Update the ride sets to make sure we have the latest before continuing on.
    dispatch({ type: 'UPDATE_RIDE', data: machineConfig.sets })
    dispatch({ type: 'CHANGE_STATUS', data: machineConfig.status })
  }
}

/**
 * Ready ride
*/
export function readyRide (id, sets) {
  return async dispatch => {
    let machineConfig = await app.service('machineConfig').patch(id, { status: 'ready', sets })
    app.service('message').create({ id, event: 'readyRide' })
    dispatch({ type: 'UPDATE_RIDE', data: machineConfig.sets })
    dispatch({ type: 'CHANGE_STATUS', data: machineConfig.status })
  }
}

/**
 * Start a simulated ride
 */
export function startRide (id) {
  return async dispatch => {
    app.service('message').create({ id, event: 'startRide' })
  }
}

/**
 * Pause ride
 */
export function pauseRide (id) {
  return async dispatch => {
    app.service('message').create({ id, event: 'pauseRide' })
  }
}

/**
 * Resume ride
 */
export function resumeRide (id) {
  return async dispatch => {
    app.service('message').create({ id, event: 'resumeRide' })
  }
}

/**
 * Enter manual mode
 */
export function manualMode (id) {
  return async dispatch => {
    app.service('message').create({ id, event: 'manualMode' })
  }
}

/**
 * Return home
 */
export function returnHome (id) {
  return async dispatch => {
    app.service('message').create({ id, event: 'returnHome' })
  }
}

/**
 * Reset machine config back to its initial state
 */
export function resetMachine (id) {
  return async dispatch => {
    // @todo use `app.service('message').create({ id, event: 'resetMachine' })
    let machineConfig = await app.service('machineConfig').patch(id, {
      patient: null,
      ride: null,
      sets: null,
      progress: null,
      status: 'idle',
    })
    dispatch({ type: 'SELECT_MACHINE', data: machineConfig })
  }
}

/**
 * Clear error messages
 */
export function dismissModal () {
  return async dispatch => {
    dispatch({ type: 'DISMISS_MODAL' })
  }
}
