import React, { Component } from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    idle: state.idle.idle
  }
}

class Idle extends Component {
  unsetIdle = () => {
    this.props.dispatch({ type: 'UNSET_IDLE' })
  }

  render () {
    return (
      <div
        onClick={this.unsetIdle}
        className="page idle">
        <i className="icon idleLogo"></i>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Idle)
